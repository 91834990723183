import Cmp from './Announcements.js'
import { graphql } from 'gatsby'

export const pageQuery = graphql`
  query currentAnnouncementPageQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      contentful_id
      title
      slug
      node_locale
    }
    isNotifications: allContentfulNotifications(
      filter: {
        active: {eq: true},
        node_locale: {eq: "is-IS"}
      },
      sort: {
        fields: createdAt,
        order: DESC
      }
    ) {
      edges {
        node {
          id
          node_locale
          contentful_id
          title
          active
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    enNotifications: allContentfulNotifications(
      filter: {
        active: {eq: true},
        node_locale: {eq: "en-US"}
      },
      sort: {
        fields: createdAt,
        order: DESC
      }
    ) {
      edges {
        node {
          id
          node_locale
          contentful_id
          title
          active
          content {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`

export default Cmp
