import React from 'react'
import Main from '../../layouts/Main'
import { TRANSLATIONS } from '../../misc/translations'
import { get, remove } from 'lodash'

const Announcements = ({ data }) => {
  const { isNotifications, enNotifications, contentfulPage } = data
  const { id, title, node_locale: nodeLocale, contentful_id: contentulId } = contentfulPage
  const notifications = nodeLocale === 'en-US' ? enNotifications.edges : isNotifications.edges

  // remove default values notification 7zYxMa5OYDEV7HAdEgSX4y
  remove(notifications, {node: { contentful_id: '7zYxMa5OYDEV7HAdEgSX4y' }})

  return (
    <Main currentId={id} currentLang={nodeLocale} currentPageId={contentulId}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 main-content'>
            <div>
              <div className='row justify-content-center'>
                <div className='col-10'>
                  <div className='wysiwyg-content'>
                    <h1>{title}</h1>
                    {notifications.map((item, i) => {
                      const { node } = item
                      const notificationContent = get(node, 'content.childMarkdownRemark.html', '')

                      return (
                        <div key={i} className='notification__single' id={item.node.contentful_id}>
                          <h2>{node.title}</h2>
                          {notificationContent &&
                            <div dangerouslySetInnerHTML={{ __html: notificationContent }} />
                          }
                        </div>
                      )
                    })}
                    {notifications.length < 1 &&
                      <h2>{TRANSLATIONS[nodeLocale].NONOTIFICATIONS}</h2>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  )
}

export default Announcements
